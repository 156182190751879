import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React, { useEffect, useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import e_chassis from '../images/menu-icons/equipments/chassis-types.png';
import e_dryvan from '../images/menu-icons/equipments/dry-vans.png';
import e_flatbed from '../images/menu-icons/equipments/flatbeds.png';
import e_intermodal from '../images/menu-icons/equipments/intermodal.png';
import e_storage from '../images/menu-icons/equipments/storage.png';
import e_temp from '../images/menu-icons/equipments/temperature.png';
import {
  default as dryvan,
  default as heated,
  default as usc
} from '../images/menu-icons/services/dry-van.png';
import {
  default as expedite,
  default as refrigerated
} from '../images/menu-icons/services/expedited.png';
import flatbed from '../images/menu-icons/services/flatbed.png';
import {
  default as cus,
  default as lessthan
} from '../images/menu-icons/services/less-than.png';
import tradeshow from '../images/menu-icons/services/tradeshow.png';
import fulltruckload from '../images/menu-icons/services/truckload.png';
import warehousing from '../images/menu-icons/services/warehousing.png';
import phone from '../images/phone.svg';
import Button from './Button';

const Navigation = () => {
  const [navBar, setNavBar] = useState(false)

  useEffect(() => {
    const changeBackground = () => {
      if (window.scrollY >= 80) {
        setNavBar(true)
      } else {
        setNavBar(false)
      }
    }

    window.addEventListener('scroll', changeBackground)
  }, [])

  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 300, maxHeight: 113) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  const logo = data.desktop.childImageSharp.fluid
  return (
    <>
      <div className={navBar ? 'nav active' : 'nav'}>
        <Link to="/">
          <Img className="nav__logo" fluid={logo} alt="logo" aria-label="logo" />
        </Link>
        <div className="nav__menu">
          <Container fluid className="nav__container-upper">
            <Row>
              <Col className="nav__col-upper-right" xl={12}>
                <div className="nav__icon-wrapper">
                  <Image src={phone} className="nav__icon" />
                  <a href="tel:+19057601141" className="nav__contact-text">
                    905-760-1141
                  </a>
                </div>
                <Link to="/shipping-rates/">
                  <Button type="primary" label="Get Free Estimate" />
                </Link>
              </Col>
            </Row>
          </Container>
          <Container fluid className="nav__container-lower">
            <Row className="nav__row">
              <Col className="nav__col-right" lg={12} xl={12}>
                <Link className="nav__item" to="/">
                  Home
                </Link>
                <div className="nav__item">
                  <ul className="nav__item-service">
                    <li className="dropdown">
                      <Link className="nav__link trigger-drop" to="/services/">
                        Services 
                        {' '}
                        <ArrowDropDownIcon fontSize="default" />
                      </Link>
                      <ul className="drop">
                        <li className="dropdown">
                          <Link to="/services/dry-van-freight/">
                            <Image src={dryvan} fluid className="nav__image" />
                            Dry Van Freight
                          </Link>
                        </li>
                        <li className="dropdown">
                          <Link to="/services/reefer-freight/">
                            <Image
                              src={refrigerated}
                              fluid
                              className="nav__image"
                            />
                            Refrigerated Shipping
                          </Link>
                        </li>
                        <li className="dropdown">
                          <Link to="/services/flatbed-freight/">
                            <Image src={flatbed} fluid className="nav__image" />
                            Flatbed
                          </Link>
                        </li>
                        <li className="dropdown">
                          <Link to="/services/truckload-ftl/">
                            <Image
                              src={fulltruckload}
                              fluid
                              className="nav__image"
                            />
                            Full Truckload
                          </Link>
                        </li>
                        <li className="dropdown">
                          <Link to="/services/less-than-truckload-ltl/">
                            <Image
                              src={lessthan}
                              fluid
                              className="nav__image"
                            />
                            Less than truckload
                          </Link>
                        </li>
                        <li className="dropdown">
                          <Link to="/services/heated-trucks/">
                            <Image src={heated} fluid className="nav__image" />
                            Heated Trucks
                          </Link>
                        </li>
                        <li className="dropdown">
                          <Link to="/services/warehouse-storage/">
                            <Image
                              src={warehousing}
                              fluid
                              className="nav__image"
                            />
                            Warehousing Solution
                          </Link>
                        </li>
                        <li className="dropdown">
                          <Link to="/services/trade-show/">
                            <Image
                              src={tradeshow}
                              fluid
                              className="nav__image"
                            />
                            Trade Show Shipping
                          </Link>
                        </li>
                        <li className="dropdown">
                          <Link to="/services/expedite-services/">
                            <Image
                              src={expedite}
                              fluid
                              className="nav__image"
                            />
                            Expedite Services
                          </Link>
                        </li>
                        <li className="dropdown">
                          <Link to="/amazon-fba-shipping/">
                            <Image src={usc} fluid className="nav__image" />
                            Amazon FBA Shipping
                          </Link>
                        </li>
                        <li className="dropdown">
                          <Link to="/cross-border-shipping/" className="trigger-drop">
                            <Image src={cus} fluid className="nav__image" />
                            Cross-border Shipping 
                            {' '}
                            <ArrowRightIcon fontSize="default" />
                          </Link>
                          <ul className="drop">
                            <li className="dropdown">
                              <Link to="/services/shipping-from-us-to-canada/">
                                <Image src={usc} fluid className="nav__image" />
                                US to Canada
                              </Link>
                            </li>
                            <li className="dropdown">
                              <Link to="/services/shipping-from-canada-to-us/">
                                <Image src={cus} fluid className="nav__image" />
                                Canada to US
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div className="nav__item">
                  <ul className="nav__item-service">
                    <li className="dropdown">
                      <Link className="nav__link trigger-drop" to="/equipment/">
                        Equipment
                        {' '}
                        <ArrowDropDownIcon fontSize="default" />
                      </Link>

                      <ul className="drop">
                        <li className="dropdown">
                          <Link to="/equipment/dry-van/">
                            <Image
                              src={e_dryvan}
                              fluid
                              className="nav__image"
                            />
                            Dry van
                          </Link>
                        </li>
                        <li className="dropdown">
                          <Link to="/equipment/temperature-controlled/">
                            <Image src={e_temp} fluid className="nav__image" />
                            Temperature Controlled
                          </Link>
                        </li>
                        <li className="dropdown">
                          <Link to="/equipment/flatbeds/">
                            <Image
                              src={e_flatbed}
                              fluid
                              className="nav__image"
                            />
                            Flatbeds
                          </Link>
                        </li>
                        <li className="dropdown">
                          <Link to="/equipment/intermodal-containers/">
                            <Image
                              src={e_intermodal}
                              fluid
                              className="nav__image"
                            />
                            Intermodal Containers
                          </Link>
                        </li>
                        <li className="dropdown">
                          <Link to="/equipment/storage-trailers/">
                            <Image
                              src={e_storage}
                              fluid
                              className="nav__image"
                            />
                            Storage Trailers
                          </Link>
                        </li>
                        <li className="dropdown">
                          <Link to="/equipment/chassis-types/">
                            <Image
                              src={e_chassis}
                              fluid
                              className="nav__image"
                            />
                            Chassis Types
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div className="nav__item">
                  <ul className="nav__item-service">
                    <li className="dropdown">
                      <Link className="nav__link trigger-drop" to="/e-forms/">
                        e-Forms 
                        {' '}
                        <ArrowDropDownIcon fontSize="default" />
                      </Link>
                      <ul className="drop">
                        <li className="dropdown">
                          <Link to="/bill-of-lading/">
                            Bill of Lading
                          </Link>
                        </li>
                        <li className="dropdown">
                          <Link to="/credit-application/">
                            Credit Application
                          </Link>
                        </li>
                        <li className="dropdown">
                          <Link to="/credit-card-auth/">
                            Credit Card Authentication
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div className="nav__item">
                  <ul className="nav__item-service">
                    <li className="dropdown">
                      <a className="nav__link trigger-drop" href="#">
                        Company 
                        {' '}
                        <ArrowDropDownIcon fontSize="default" />
                      </a>
                      <ul className="drop">
                        <li className="dropdown">
                          <Link to="/blog/">
                            Free Resources
                          </Link>
                        </li>
                        <li className="dropdown">
                          <Link to="/about-us/">
                            About Us
                          </Link>
                        </li>
                        <li className="dropdown">
                          <Link to="/contact-us/">
                            Contact
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <div className="navigation">
        <input
          type="checkbox"
          className="navigation__checkbox"
          id="navi-toggle"
        />
        <Link to="/" className="navigation__logo-mob-container">
          <Img className="navigation__logo-mob" fluid={logo} alt="logo" aria-label="logo" />
        </Link>

        <label htmlFor="navi-toggle" className="navigation__button">
          <span className="navigation__icon">&nbsp;</span>
        </label>

        <div className="navigation__background">&nbsp;</div>
        <nav className="navigation__nav">
          <ul className="navigation__list">
            <li className="navigation__item">
              <Link className="navigation__link" to="/">
                Home
              </Link>
            </li>
            <li className="navigation__item">
              <Link className="navigation__link" to="/services/">
                Services
              </Link>
            </li>
            <li className="navigation__item">
              <Link className="navigation__link" to="/equipment/">
                Equipment
              </Link>
            </li>
            <li className="navigation__item">
              <Link className="navigation__link" to="/e-forms/">
                e-Forms
              </Link>
            </li>
            <li className="navigation__item">
              <Link className="navigation__link" to="/blog/">
                Free Resources
              </Link>
            </li>
            <li className="navigation__item">
              <Link className="navigation__link" to="/about-us/">
                About Us
              </Link>
            </li>
            <li className="navigation__item">
              <Link className="navigation__link" to="/contact-us/">
                Contact
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </>
  )
}

export default Navigation

import React, { useState } from 'react'
import TawkChatIcon from './tawk-chat-mini.svg'

const TawkMini = () => {
    const [ChatBox, setChatBox] = useState(false)
    const miniclick = (event) => {
        console.log('sdkfjksdjfksdjf')
        const Neither = document.getElementById('tawk_5f6a2cd6f0e7167d0012c54d');
        ChatBox ? Neither.style = 'display:none !important' : Neither.style = 'display:block !important';
        setChatBox(!ChatBox);
    }
    return (
        <div>
            <button onClick={miniclick} className="tawk-custom-minimize">
                <span className="tawk-badge">1</span>
                <img src={TawkChatIcon} alt="TawkChatIcon" />
            </button>
        </div>
    )
}

export default TawkMini

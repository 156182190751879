import {
  createMuiTheme,
  ThemeProvider
} from '@material-ui/core/styles'
import React from 'react'
// import './all.sass'
import '../sass/_main.scss'
import Navigation from "./Navigation"
import TawkMini from './tawk'


const theme = createMuiTheme({
  typography: {
    label: {
      fontWeight: 500,
    },
    fontFamily: [
      'Montserrat',
      'sans-serif'
    ].join(','),
    fontSize: 20,
  },
})

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>

    <Navigation />
    <div>{children}</div>
    <TawkMini />
  </ThemeProvider>
)

export default Layout
